import React from 'react';
import styles from "../assets/css/checkout.module.css";

const PaymentInfo = ({ paymentMethod, handlePaymentChange }) => (
  <div className={styles.paymentInfo}>
    <h2 className={styles.header2}>Método de Pago</h2>
    <label className={styles.label} htmlFor="payment-method">Selecciona el método de pago</label>
    <select
      id="payment-method"
      value={paymentMethod}
      onChange={handlePaymentChange}
    >
      <option value="">Selecciona metodo de pago</option>
      <option value="tarjeta">Tarjeta de Crédito</option>
      <option value="efectivo">Efectivo</option>
      <option value="paypal">PayPal</option>
    </select>
  </div>
);

export default PaymentInfo;
