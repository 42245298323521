import React, { useState, useEffect } from 'react';

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <div className="countdown d-flex">
      <div className="single-count-content">
        <span className="count">{timeLeft.days || 0}</span>
        <p className="text">Days</p>
      </div>
      <div className="single-count-content">
        <span className="count">{timeLeft.hours || 0}</span>
        <p className="text">Hours</p>
      </div>
      <div className="single-count-content">
        <span className="count">{timeLeft.minutes || 0}</span>
        <p className="text">Minutes</p>
      </div>
      <div className="single-count-content">
        <span className="count">{timeLeft.seconds || 0}</span>
        <p className="text">Seconds</p>
      </div>
    </div>
  );
};

export default Countdown;
