import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComingSoon from "./ComingSoon";
import MenuPage from "./pages/MenuPage";  
import NotFoundPage from "./pages/NotFoundPage"
import CheckoutPage from "./pages/CheckoutPage"
import OrdenPage from "./pages/OrdenPage"
import BuscarOrden from "./pages/BuscarOrden"
import PaymentPage from "./components/PaymentPage";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="/:id" element={<MenuPage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/:id/checkout" element={<CheckoutPage />} />
          <Route path="/:id/orden/:ordenid" element={<OrdenPage />} />
          <Route path="/:id/orden" element={<BuscarOrden />} />
          <Route path="/stripe" element={<PaymentPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
