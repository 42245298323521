// CustomerInfo.js
import React from 'react';
import styles from "../assets/css/checkout.module.css";

const CustomerInfo = ({ contactName, setContactName, contactEmail, setContactEmail, contactPhone, setContactPhone, errors }) => {
  return (
    <div className={styles.customerInfo}>
      <h2 className={styles.header2}>Información del Cliente</h2>

      <label className={styles.label} htmlFor="contact-name">Nombre de Contacto</label>
      <input
        type="text"
        id="contact-name"
        className={errors.contactName ? 'error' : ''}
        placeholder="ingresa tu nombre"
        value={contactName}
        onChange={(e) => setContactName(e.target.value)}
      />
      {errors.contactName && <span style={{ color: 'red' }}>{errors.contactName}</span>}

      <label className={styles.label} htmlFor="contact-email">Email</label>
      <input
        type="email"
        id="contact-email"
        className={errors.contactEmail ? 'error' : ''}
        value={contactEmail}
        onChange={(e) => setContactEmail(e.target.value)}
        placeholder="ejemplo@dominio.com"
      />
      {errors.contactEmail && <span style={{ color: 'red' }}>{errors.contactEmail}</span>}

      <label className={styles.label} htmlFor="contact-phone">Teléfono</label>
      <input
        type="tel"
        id="contact-phone"
        className={errors.contactPhone ? 'error' : ''}
        value={contactPhone}
        onChange={(e) => setContactPhone(e.target.value)}
        placeholder="(555) 123-4567"
      />
      {errors.contactPhone && <span style={{ color: 'red' }}>{errors.contactPhone}</span>}
    </div>
  );
};

export default CustomerInfo;
