import React from 'react';

const Navbar = ({ title, menuColor, openCartModal, cartItemCount  }) => {
    let titleUppercase = title.toUpperCase();
    
    const styles = {
      menuBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    /*    backgroundColor: '#FE61DC',*/
        backgroundColor: menuColor,
        color: 'white',
        padding: '10px 20px',
        position: 'relative',
      },
      title: {
        margin: 0,
        fontSize: '24px',
      },
      links: {
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
      },
      link: {
        color: 'white',
        textDecoration: 'none',
        margin: '0 15px',
        transition: 'color 0.3s',
      },
      cartIcon: {
        fontSize: '24px',
        cursor: 'pointer',
      },
    };
    
  return (
    <div style={styles.menuBar}>
      <h1 style={styles.title}>{titleUppercase}</h1>
      <div style={styles.links}>
        {/*
        <a href="#comida" style={styles.link}>Comida</a>
        <a href="#bebidas" style={styles.link}>Bebidas</a>
        <a href="#postres" style={styles.link}>Postres</a>
       */}
      </div>
      <div style={styles.cartIcon} onClick={openCartModal}>🛒 Carrito &nbsp;  
      {cartItemCount > 0 && (
           <span style={styles.cartCount}> {cartItemCount}</span>  
        )}
      </div>  
    </div>
  );
};


export default Navbar;
