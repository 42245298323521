import React, { useState, useEffect } from 'react';

const LoadingSpinner = () => {
  const [loadingText, setLoadingText] = useState('🍳');
  
  useEffect(() => {
    const frames = ['🍳', '🍳', '👨‍🍳', '👨‍🍳', '🍳', '👨‍🍳'];
    let frameIndex = 0;

    const interval = setInterval(() => {
      frameIndex = (frameIndex + 1) % frames.length;
      setLoadingText(frames[frameIndex]);
    }, 300); // Cambia el frame cada 300 ms

    return () => clearInterval(interval); // Limpiar el intervalo cuando se desmonta el componente
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.loader}>Cargando Menú</div>
      <div style={styles.loadingIcon}>{loadingText}</div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7',
  },
  loader: {
    fontSize: '40px',
    fontFamily: 'monospace',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#0000',
    WebkitTextStroke: '1px #000',
    background:
      'conic-gradient(#000 0 0) no-repeat text, conic-gradient(#000 0 0) no-repeat text',
    backgroundPositionY: '100%',
    animation: 'l15 3s infinite',
    marginBottom: '20px',
    textAlign: 'center', // Centra el texto
  },
  loadingIcon: {
    fontSize: '80px', // Tamaño del icono de carga
    animation: 'bounce 1s infinite',
    textAlign: 'center', // Centra el icono
  },
};

// Añadir animación a la carga del texto
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
}`, styleSheet.cssRules.length);

styleSheet.insertRule(`
  @keyframes l15 {
    0%     {background-size: 1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   }
    14.28% {background-size: 1ch 100%,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   }
    28.57% {background-size: 1ch 100%,1ch 100%,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   }
    42.85% {background-size: 1ch 100%,1ch 100%,1ch 100%,1ch 0   ,1ch 0   ,1ch 0   ,1ch 0   }
    57.14% {background-size: 1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 0   ,1ch 0   ,1ch 0   }
    71.43% {background-size: 1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 0   ,1ch 0   }
    85.71% {background-size: 1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 0   }
    100%   {background-size: 1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 100%,1ch 100%}
  }
`, styleSheet.cssRules.length);

export default LoadingSpinner;
