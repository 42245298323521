import React, { useState } from 'react';
import { enviaEmails } from './services/authService';
import './assets/css/bootstrap-4.5.0.min.css';
import './assets/css/style.css';
import Countdown from './Countdown';

const ComingSoon = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(''); // Nueva variable de estado para el mensaje de éxito

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const enviaEmail = async (event) => {
        event.preventDefault();
        setError('');
        setSuccessMessage(''); // Limpiar mensaje de éxito al iniciar

        if (!email || !validateEmail(email)) {
            setError('Por favor, ingrese un correo electrónico válido.');
            return;
        }

        setLoading(true);
        try {
            const response = await enviaEmails(email); // Asegúrate de que enviaEmails esté implementado correctamente
            console.log(response);
            if (response.message) {
                setSuccessMessage('¡Correo enviado con éxito!'); // Mostrar mensaje de éxito
                setEmail(''); // Limpiar el campo de correo después de enviar
            } else {
                setError('Error en la respuesta del servidor.');
            }
        } catch (error) {
            setError('Error al enviar el correo. Inténtalo más tarde.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <main className="main-14">
            <div className="main-wrapper demo-14">
                {/* shapes start */}
                <img src="/img/shape-1.svg" alt="" className="shape shape-1" />
                <img src="/img/shape-2.svg" alt="" className="shape shape-2" />
                <img src="/img/shape-3.svg" alt="" className="shape shape-3" />
                <img src="/img/shape-5.svg" alt="" className="shape shape-5" />
                <img src="/img/shape-6.svg" alt="" className="shape shape-6" />
                {/* shapes end */}

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-6 col-md-6">
                            <div className="img-wrapper wow fadeInLeft" data-wow-delay=".2s">
                                <img src="/img/img-1.svg" alt="" />
                            </div>
                        </div>

                        <div className="col-xl-7 col-lg-6 col-md-6 odd-col">
                            <div className="content-wrapper">
                                <h1 className="wow fadeInDown" data-wow-delay=".2s">"pallevar" Viene Pronto!</h1>
                                <p className="wow fadeInUp" data-wow-delay=".4s">
                                    Muy pronto, Pallevar llegará para ayudar a los pequeños establecimientos de comida a tomar órdenes en línea, agilizar su productividad e incrementar su clientela. ¡Estamos aquí para hacer crecer tu negocio!
                                </p>

                                <div className="wow fadeInLeft" data-wow-delay=".2s">
                                    <Countdown targetDate="2025/01/01" />
                                </div>
                                {error && <div className="error-message">{error}</div>}
                                {successMessage && <div className="success-message">{successMessage}</div>} {/* Mostrar mensaje de éxito */}
                                <form
                                    onSubmit={enviaEmail}
                                    className="subscribe-form subscribe-form-1 validate wow fadeInDown"
                                    data-wow-delay=".6s"
                                    noValidate
                                >
                                    <input
                                        type="email"
                                        name="EMAIL"
                                        id="EMAIL"
                                        className="required email"
                                        placeholder="Ingresa tu correo electrónico"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} // Asegúrate de que onChange esté aquí
                                    />

                                    <div id="mce-responses" className="clear">
                                        <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                                        <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                                    </div>

                                    <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                                        <input
                                            type="text"
                                            name="b_4dbefd9d3a1d6a570020b1e85_e16d098ae8"
                                            tabIndex="-1"
                                            defaultValue="" // Cambié value a defaultValue para evitar advertencias
                                        />
                                    </div>

                                    <button id="subscribe" className="sub_btn common-btn" disabled={loading}>
                                        {loading ? 'Enviando...' : 'Únete a la lista de espera'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer-social">
                <div className="social-wrapper">
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <img src="/img/icons8-linkedin-96.svg" alt="Twitter" />
                    </a>
                    <a href="https://www.instagram.com/pallevar.me/" target="_blank" rel="noopener noreferrer">
                        <img src="/img/icons8-instagram-96.svg" alt="Instagram" />
                    </a>
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                        <img src="/img/icons8-facebook-96.svg" alt="Facebook" />
                    </a>
                </div>
            </footer>
        </main>
    );
};

export default ComingSoon;
