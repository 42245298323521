import React from "react";

const FoodMenu = ({id, platillo, precio, img, onViewDetails, onAddToCart, hide  }) => {
  const isHidden = hide === "true"
  return ( 
    !isHidden && (
    <div
      style={styles.menuItem}
      key={id}
      onMouseEnter={(e) => (e.currentTarget.style.transform = styles.menuItemHover.transform)}
      onMouseLeave={(e) => (e.currentTarget.style.transform = "")}  
    >
      <img src={img} alt={platillo} style={styles.img} />
      <h3 style={styles.title}>{platillo}</h3>
      <span style={styles.price}>${precio} MXN</span>
      <span style={styles.price}>{hide}</span>
      <button style={styles.button} onClick={onAddToCart}>Agregar al carrito</button>
      <button style={styles.button} onClick={onViewDetails}>Ver detalle</button>
    </div>
    )
   );
};

const styles = {
    menuItem: {
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      transition: "transform 0.3s",
      cursor: "pointer",
    },
    menuItemHover: {
      transform: "translateY(-5px)",
    },
    img: {
      width: "100%",
      height: "150px",
      objectFit: "cover",
      borderRadius: "8px",
      border: "1px solid #eee",
    },
    title: {
      margin: "10px 0",
      color: "#333",
    },
    description: {
      color: "#666",
      fontSize: "14px",
      margin: "10px 0",
    },
    price: {
      color: "#FE61DC",
      fontWeight: "bold",
      fontSize: "18px",
      display: "block",
      marginBottom: "10px",
    },
    button: {
      backgroundColor: "#0065FF",
      border: "none",
      padding: "10px",
      color: "white",
      cursor: "pointer",
      borderRadius: "4px",
      margin: "5px 0",
      width: "100%",
      transition: "background-color 0.3s",
    },
    buttonHover: {
      backgroundColor: "#0053cc",
    },
  };

export default FoodMenu;