// NotFoundPage.jsx
import React from 'react';

const NotFoundPage = () => {
  return (
    <div style={styles.pageContainer}>


      <section style={styles.mainContent}>
        <h2 style={styles.title}>404 - Menú no encontrado</h2>
        <p style={styles.message}>El menú que estás buscando no está disponible o no existe.</p>
        <div style={styles.buttonContainer}>
          <button
            style={styles.button}
            onMouseEnter={(e) => (e.target.style.backgroundColor = '#004bb5')}
            onMouseLeave={(e) => (e.target.style.backgroundColor = '#0065FF')}
            onClick={() => window.location.href = '/'}
          >
            Volver al inicio
          </button>
        </div>
      </section>
    </div>
  );
};

const styles = {
  pageContainer: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f7f7f7',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  header: {
    backgroundColor: '#FE61DC',
    color: 'white',
    width: '100%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  },
  headerText: {
    margin: 0,
    fontSize: '26px',
    fontWeight: 'bold',
  },
  mainContent: {
    textAlign: 'center',
    padding: '25px',
    maxWidth: '500px',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    marginTop: '20px',
  },
  title: {
    fontSize: '24px',
    color: '#333',
    marginBottom: '10px',
  },
  message: {
    color: '#666',
    fontSize: '16px',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#0065FF',
    color: 'white',
    padding: '10px 25px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontSize: '16px',
    fontWeight: 'bold',
  },
};

export default NotFoundPage;
