import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import styles from "../assets/css/stripe.module.css";
import { processPayment, procesaOrden } from "../services/authService";

const stripePromise = loadStripe('pk_test_51NgaVrBijbKqi14WVjpamCR7KqtQ7679s5AaMm1LBGLjuBO1HUOQR4iPoPQhgwMsGYZSXgDHMZQox9A6Sw8Dt5dY00Bfr1y1OC');

const CheckoutForm = ({ orderData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setMessage("Stripe no está cargado.");
      setLoading(false);
      return;
    }

    try {
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        setMessage(error.message);
        setLoading(false);
        return;
      }

      // Combina los datos del método de pago con los datos del pedido
      const paymentData = {
        paymentMethodId: paymentMethod.id,
        orderData, // Incluye los datos del pedido completo
      };

      const result = await processPayment(paymentData);

      if (result.success) {
        setMessage("Pago exitoso!");
        // procesar orden
        const orderResponse = await procesaOrden(orderData);
        console.log(orderResponse);
        console.log('Orden procesada:', orderResponse.orderId);
      } else {
        setMessage(`Error: ${result.error}`);
      }
    } catch (error) {
      setMessage("Ocurrió un error procesando el pago.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.formContainer}>
      <CardElement
        className={styles.cardElement}
        options={{ style: { base: { fontSize: "16px" } } }}
      />
      <button type="submit" className={styles.submitButton} disabled={!stripe || loading}>
        {loading ? "Procesando..." : "Pagar"}
      </button>
      {message && <div className={styles.message}>{message}</div>}
    </form>
  );
};

const PaymentPage = ({ orderData }) => (
  <div className={styles.creditCardInfo}>
    <Elements stripe={stripePromise}>
      <CheckoutForm orderData={orderData} />
    </Elements>
  </div>
);

export default PaymentPage;
