import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const CartModal = ({ cartItems, onRemove, onClose }) => {
  const navigate = useNavigate();
  const { id } = useParams(); // Obtén el ID del restaurante desde la URL

  const handleCheckout = () => {
    navigate(`/${id}/checkout`, { state: { cartItems } }); // Pasa cartItems en el state
  };
  

  const totalItems = cartItems.length;
  const totalPrice = cartItems.reduce((total, item) => total + (parseFloat(item.precio) * item.quantity), 0);

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <h2 style={styles.modalTitle}>Carrito ({totalItems} {totalItems === 1 ? 'artículo' : 'artículos'})</h2>
        {totalItems === 0 ? (
          <p style={styles.emptyCartMessage}>No hay artículos en el carrito.</p>
        ) : (
          <ul style={styles.itemList}>
            {cartItems.map((item) => (
              <li key={item._id} style={styles.listItem}>
                <img style={styles.imagen} src={item.imgPlatillo} alt={item.imgPlatillo}  />
                <span style={styles.itemName}>{item.nombrePlatillo} (x{item.quantity})</span>
                <span style={styles.itemPrice}>${(parseFloat(item.precio) * item.quantity).toFixed(2)}</span>
                <button style={styles.removeButton} onClick={() => onRemove(item._id)}>Eliminar</button>
              </li>
            ))}
          </ul>
        )}
        <h3 style={styles.totalPrice}>Total: ${totalPrice.toFixed(2)} MXN</h3>
        <div style={styles.buttonContainer}>
          <button style={styles.checkoutButton} onClick={handleCheckout}>Procesar Pedido</button>
          <button style={styles.closeButton} onClick={onClose}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};



const styles = {
  imagen:{
    width: '40px',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Oscurecer un poco más
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '400px', // Aumenta el ancho
    maxHeight: '80vh', // Limitar la altura
    overflowY: 'auto', // Permitir desplazamiento si hay muchos elementos
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
  },
  modalTitle: {
    margin: '0 0 15px 0',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  emptyCartMessage: {
    textAlign: 'center',
    fontStyle: 'italic',
    color: '#777',
  },
  itemList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid #eee',
  },
  itemName: {
    flex: 1,
  },
  itemPrice: {
    marginRight: '10px',
  },
  removeButton: {
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  removeButtonHover: {
    backgroundColor: '#c00',
  },
  totalPrice: {
    fontWeight: 'bold',
    textAlign: 'right',
    margin: '15px 0',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkoutButton: {
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 15px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    flex: 1,
    marginRight: '5px',
  },
  closeButton: {
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 15px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    flex: 1,
  },
};

export default CartModal;
