import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getMenu, getMenuColor } from "../services/authService";
import LoadingSpinner from '../components/LoadingSpinner';
import NotFoundPage from "./NotFoundPage";
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import CategoryTabs from "../components/CategoryTabs";
import FoodMenu from '../components/FoodMenu';
import Modal from '../components/Modal';
import Footer from '../components/Footer';
import CartModal from '../components/CartModal';


const MenuPage = () => {
  const [activeCategory, setActiveCategory] = useState('food');
  const { id } = useParams();
  const [menuItems, setMenuItems] = useState(null);
  const [menuColor, setMenuColor] = useState('#FE61DC');
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  
  const cartKey = `cart_${id}`; 
  //console.log("cartKey:",cartKey)
  // Cargar el carrito desde localStorage
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem(cartKey);
    return savedCart ? JSON.parse(savedCart) : []; // Si hay un carrito guardado, cargarlo; si no, iniciar vacío
  });

  const [isCartModalOpen, setCartModalOpen] = useState(false); // Estado para el modal del carrito

  useEffect(() => {
    document.body.style.backgroundColor = '#f7f7f7';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const items = await getMenu(id);
        console.log(items)
        setMenuItems(items);
      } catch (error) {
        console.error("Error al obtener los elementos del menú:", error);
        setMenuItems([]); // Para manejar errores
      } finally {
        setLoading(false);
      }
    };

    const fetchMenuColor = async () => {
      try {
        const color = await getMenuColor(id);
        if (color) setMenuColor(color);
      } catch (error) {
        console.error("Error al obtener el color del menú:", error);
      } //finally {
      //  setLoadingColor(false);
     // }
    };

    fetchMenuItems();
    fetchMenuColor();
  }, [id]);

  
  // Guardar el carrito en localStorage cada vez que cambie
  useEffect(() => {
    localStorage.setItem(cartKey, JSON.stringify(cartItems));
  }, [cartKey, cartItems]);

  const addToCart = (item) => {
    console.log("Agregando al carrito:", item); // Verifica si se llama correctamente
    setCartItems((prevItems) => {
        const existingItem = prevItems.find(cartItem => cartItem._id === item._id);
        if (existingItem) {
            return prevItems.map(cartItem =>
                cartItem._id === item._id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
            );
        }
        return [...prevItems, { ...item, quantity: 1 }];
    });
  };

  const removeFromCart = (itemId) => {
    setCartItems((prevItems) => {
      const itemToRemove = prevItems.find(item => item._id === itemId);
      if (itemToRemove.quantity > 1) {
        // Si hay más de una cantidad, decrementa la cantidad
        return prevItems.map(item =>
          item._id === itemId ? { ...item, quantity: item.quantity - 1 } : item
        );
      }
      // Si solo hay una cantidad, elimina el artículo
      return prevItems.filter(item => item._id !== itemId);
    });
  };

  const openCartModal = () => {
    setCartModalOpen(true);
  };

  const closeCartModal = () => {
    setCartModalOpen(false);
  };

  const cartItemCount = cartItems.reduce((total, item) => total + item.quantity, 0);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!menuItems || (menuItems.Comidas?.length === 0 && menuItems.Bebidas?.length === 0)) {
    return <NotFoundPage />;
  }

  const isFoodActive = activeCategory === 'food';
  const isDrinksActive = activeCategory === 'drinks';
  const isEntradasActive = activeCategory === 'entradas';
  const isPostresActive = activeCategory === 'postres';

  return (
    <div>
      <Navbar title={id}  menuColor={menuColor} openCartModal={openCartModal} cartItemCount={cartItemCount} />  
      <Header menuColor={menuColor}/>
      <div>
        <CategoryTabs menuColor={menuColor} activeTab={activeCategory} onTabClick={setActiveCategory} />

        {isEntradasActive && (
          <section id="entradas" className="menu active">
            {menuItems.Entradas?.map((item) => (
              <FoodMenu
                key={item._id}
                id={item._id}
                platillo={item.nombrePlatillo}
                precio={item.precio}
                descripcion={item.descripcion}
                img={item.imgPlatillo}
                hide={item.ocultar}
                onViewDetails={() => setSelectedItem(item)}
                onAddToCart={() => addToCart(item)} // Asegúrate de pasar esta función
              />
            ))}
          </section>
        )}

        {isFoodActive && (
          <section id="food" className="menu active">
            {menuItems.Comidas?.map((item) => (
              <FoodMenu
                key={item._id}
                id={item._id}
                platillo={item.nombrePlatillo}
                precio={item.precio}
                descripcion={item.descripcion}
                img={item.imgPlatillo}
                hide={item.ocultar}
                onViewDetails={() => setSelectedItem(item)}
                onAddToCart={() => addToCart(item)} // Asegúrate de pasar esta función
              />
            ))}
          </section>
        )}

        {isDrinksActive && (
          <section id="drinks" className="menu active">
            {menuItems.Bebidas?.map((item) => (
              <FoodMenu
                key={item._id}
                id={item._id}
                platillo={item.nombrePlatillo}
                precio={item.precio}
                descripcion={item.descripcion}
                img={item.imgPlatillo}
                hide={item.ocultar}
                onViewDetails={() => setSelectedItem(item)}
                onAddToCart={() => addToCart(item)}
              />
            ))}
          </section>
        )}

      {isPostresActive && (
          <section id="postres" className="menu active">
            {menuItems.Postres?.map((item) => (
              <FoodMenu
                key={item._id}
                id={item._id}
                platillo={item.nombrePlatillo}
                precio={item.precio}
                descripcion={item.descripcion}
                img={item.imgPlatillo}
                hide={item.ocultar}
                onViewDetails={() => setSelectedItem(item)}
                onAddToCart={() => addToCart(item)}
              />
            ))}
          </section>
        )}
      </div>

      {selectedItem && (
        <Modal item={selectedItem} onClose={() => setSelectedItem(null)} onAddToCart={() => addToCart(selectedItem)}/>
      )}

      {isCartModalOpen && (
        <CartModal
          cartItems={cartItems}
          onRemove={removeFromCart}
          onCheckout={() => alert('Procesar pedido')}
          onClose={closeCartModal}
        />
      )}

      <Footer title={id} menuColor={menuColor}/> 
    </div>
  );
};

export default MenuPage;
