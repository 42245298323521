import React from 'react';
import styles from '../assets/css/LoadingDots.module.css'; 

const LoadingDots = () => {
  return (
    <div className={styles.loadingDots}>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
    </div>
  );
};

export default LoadingDots;
