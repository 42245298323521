import React from 'react';
import styles from "../assets/css/checkout.module.css";

const OrderSummary = ({ cartItems, totalPrice }) => (
  <div className={styles.orderSummary}>
    <h2 className={styles.header2}>Resumen de la Orden</h2>
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.th}>Platillo</th>
          <th className={styles.th}>Cantidad</th>
          <th className={styles.th}>Precio</th>
        </tr>
      </thead>
      <tbody>
        {cartItems.map((item) => (
          <tr key={item._id}>
            <td className={styles.td}>{item.nombrePlatillo}</td>
            <td className={styles.td}>{item.quantity}</td>
            <td className={styles.td}>${(parseFloat(item.precio) * item.quantity).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className={styles.orderTotal}>Total: ${totalPrice.toFixed(2)} MXN</div>
  </div>
);

export default OrderSummary;
