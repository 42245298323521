import React from 'react';
import styles from "../assets/css/checkout.module.css";

const OrderComments = ({ comments, setComments }) => {
  return (
    <div className={styles.comments}>
      <h2 className={styles.header2}>Comentarios sobre el Pedido</h2>
      <textarea
        rows="4"
        placeholder="Escribe tus comentarios aquí..."
        value={comments}
        onChange={(e) => setComments(e.target.value)}
      ></textarea>
    </div>
  );
};

export default OrderComments;
